<template>
  <div class="agreement wrapper">
    <p class="title-top" />

    <!--    eslint-disable vue/no-v-html-->
    <div
      class="div-partition"
      v-html="text"
    />
  </div>
</template>

<script>
import { formatFirstUpperCase } from '@/utils/index.js'

export default {
  metaInfo: {
    title: 'PrivacyPolicy',
    link: []
  },
  data () {
    return {
      text: `
      
      <br>
       <br>
 <h3> 1.Information We Collected</h3>
      
        <br>
         <br>
        Hololink's server automatically records information when you use Hololink, including your IP address and WLAN IP address. Additionally, at the time of registration, we retain data on your email address and any other information that you choose to denote in your profile. We also use cookies to store your account information for a convenient browsing experience.
         <br>
        <br>
        <h3>2. How We Use Your Data</h3>
      
        <br>
         <br>
            Third parties outside of Hololink may receive aggregated non-personal information. Under limited circumstances we may share personal information with third parties as well, including when complying with legal matters and also when ensuring the security of our site and related services. 
        <br>
         <br>
         <h3> 3. Your Choices and Obligations</h3>
        <br>
         <br>
            Personal information fields in your profile, outside of the necessary information to process and approve your account, are entirely optional and at your discretion to provide. Your account information at signup, specifically your valid ISP or email address.
        <br>
         <br>`,
      project: ' Netboom Game +'
    }
  },
  created () {
    const flavor = this.$route.query.flavor || ' Netboom Game +'
    this.project = formatFirstUpperCase(flavor)
  }
}
</script>

<style lang="less">
@import "~@/assets/styles/agreement.less";
</style>
<style lang="less" scoped>
.wrapper {
  padding: 0 5%;
  background: #F0F0F0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  min-height: 100%;
  box-sizing: border-box;
}
</style>
